import React, { useState } from 'react'
import 'antd/dist/antd.css';
import { Form, Input, Button, Select, Modal, message, Upload } from "antd";
const { Option } = Select;
export default function (props) {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    let children = getChildren();
    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };
    const tailLayout = {
        wrapperCol: {
            offset: 8,
            span: 16,
        },
    };
    return (
        <div>
            <h1 style={{marginLeft:"60px", marginTop:"20px"}}>Help Center</h1>
            <br />
            <h2 style={{marginLeft:"120px"}}>Submit a request</h2>
            <br />
            <br />
            <Form style={{ position: 'relative', marginLeft: '16px', maxWidth: "800px" }}
                form={form}
                {...layout}
                name="basic"
                onFinish={onFinish}
                initialValues={
                    // {
                    //     remember: false,
                    //     name: props.dataSource.name,
                    // }
                    ''
                }
            // onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Your email address"
                    name="email"
                    rules={[
                        {
                            required: true,
                            // message: 'Please input your email address',
                            type: 'email',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Select your request type"
                    name="requestType"
                    rules={[
                        {
                            required: true,
                            message: 'Please choose a type',
                        },
                    ]}
                >
                    <Select
                        // mode="multiple"
                        // allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        // defaultValue='0'
                    // onChange={handSelectCategoryChange}
                    // onClear={handClearSelectCategory}
                    >
                        {children}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter the subject',
                        },
                    ]}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter the description',
                        },
                    ]}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )

    function onFinish() {
        setIsLoading(true);
        setTimeout(()=>{
            setIsLoading(false);
            message.info("Submit Success!", 0.8);
        }, 800);
    }

    function getChildren() {
        let children = [];
        children.push(<Option key='0'>App issues</Option>)
        children.push(<Option key='1'>Suggestions</Option>)
        children.push(<Option key='2'>Other</Option>)

        return children;
    }
}