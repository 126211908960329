import React from 'react'

export default function (props) {

    return (
        <div style={{marginTop:"120px", marginBottom:"80px"}}>
            <p>{"FEEDBACK & COMPLAINT"}</p>
            
            <p> <a style={{fontStyle:"italic"}} href="/contactus">contact@dolphinreader.com</a> (For bugs, difficulties or any other problems)</p>

        </div>

    )
}
