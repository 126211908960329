import React, { useEffect, useState, useRef } from "react";
import './HomePage.css'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import ContactUs from "./element/ContactUs";

export default function (props) {

    return (
        <Router>
            <div>
                <div className='home-page-root'>
                    <Switch>
                        <Route exact path="/" >

                        </Route>
                        <Route exact path="/contactus" component={ContactUs}>

                        </Route>

                    </Switch>
                </div>
            </div>
        </Router>
    )

}


// .home-page-root{
//     background: rgb(0, 0, 0);
//     /* // background-image:url("./loginbg2.jpg"); */
//     background-repeat:no-repeat; 
//     /* // background-size:100% 100%; */
//     background-position: center;
//     background-attachment: fixed;
//     background-size:cover;
//     -moz-background-size:100% 100%;
//     /* // -moz-background-size: cover; */
//     -webkit-background-size: cover;
//     -o-background-size: cover;
//     height: auto;
//     width: auto;
//     position: absolute;
//     left: 0px;
//     top: 0px;
//     right: 0px;
// }