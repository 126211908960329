import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    BrowserRouter
} from "react-router-dom";
import HomePage from "./page/HomePage";
import SupportPage from "./page/SupportPage";

export default function () {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={HomePage}/>
                <Route exact path="/support" component={SupportPage}/>
            </Switch>
        </Router>
    )


}